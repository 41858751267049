import UserService from '../../infrastructure/UserService'

export const getUserName: any = async () => {
  let username = 'User'
  return await new Promise<string>((resolve) => {
    setTimeout(() => resolve(''), 300)
  }).then(() => {
    username = UserService.getFirstName()
    return username
  })
}
