import React, { useState } from 'react'

import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router-dom'
import { getUserName } from '../../../application/utils/authStorage'
import { MainMenu } from 'fiorde-fe-components'
import NotFound from './notFound'
import { Root, Title, WelcomeUserName, ItemContainer, Container } from './style'

const Home = (): JSX.Element => {
  const [noHasModule] = useState(false)
  const history = useHistory()
  const [username, setUserName] = useState<string>('User')

  getUserName()
    .then((result: string) => setUserName(result))
    .catch((error) => console.warn(error))

  const handleClick = (card: any): void => {
    history.push(card.url)
  }

  const menuItens: any[] = [
    {
      icon: 'exportation',
      url: '/',
      isActive: true,
      key: 'exportation',
      label: I18n.t('pages.home.exportation'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'importation',
      isActive: true,
      url: '/',
      key: 'importation',
      label: I18n.t('pages.home.importation'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'freight-forwarder',
      isActive: true,
      key: 'freight-forwarder',
      url: '/',
      label: I18n.t('pages.home.freightForwarder'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'commercial',
      url: '/comercial',
      isActive: true,
      key: 'commercial',
      label: I18n.t('pages.home.commercial'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'billing',
      isActive: true,
      key: 'billing',
      url: '/',
      label: I18n.t('pages.home.billing'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'national-logistic',
      isActive: true,
      key: 'national-logistic',
      url: '/',
      label: I18n.t('pages.home.nationalLogistic'),
      onclick: (item) => handleClick(item)
    }
  ]

  return (
    <Root>
      {noHasModule
        ? (
        <NotFound />
          )
        : (
        <>
          <Title>
            <WelcomeUserName>{I18n.t('pages.home.welcome', { name: username })}</WelcomeUserName>
            <ItemContainer>{I18n.t('pages.home.modules')}</ItemContainer>
          </Title>
          <Container>
            <MainMenu menuItems={menuItens} />
          </Container>
        </>
          )}
    </Root>
  )
}
export default Home
