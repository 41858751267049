import React from 'react'

import CButton from './style'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { light, dark } from '../../../application/themes'

const ArrowButton = (props): JSX.Element => {
  const { onPress, bold, textDecoration, lightFont, position } = props

  return (
    <CButton
      variant="text"
      startIcon={position === 'left' ? <ChevronLeftIcon /> : null}
      endIcon={position === 'right' ? <ChevronRightIcon /> : null}
      onClick={onPress}
      style={{
        color: lightFont as boolean ? dark.platform.fontColor : light.platform.fontColor,
        fontWeight: bold as boolean ? 'bold' : 'normal',
        textDecoration: textDecoration as boolean ? 'underline' : 'none'
      }}
    >
      {props.children}
    </CButton>
  )
}

export default ArrowButton
