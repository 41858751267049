import React from 'react'
import { StyledThemeProvider, dark as libraryThemeDark, light as libraryThemeLight } from 'fiorde-fe-components'
import { ThemeProvider } from 'styled-components'
import { light, dark } from '../application/themes'
import Routes from './components/Routes'
import { useSelector } from 'react-redux'
import { _kc, handleKeycloakInit } from '../infrastructure/UserService'
import { ReactKeycloakProvider } from '@react-keycloak/web'

const App = (): JSX.Element => {
  const state: any = useSelector((state): any => state)
  const { theme } = state.app

  return (
    <ReactKeycloakProvider authClient={_kc} onEvent={handleKeycloakInit}>
      <ThemeProvider theme={theme === 'light' ? light : dark}>
        <StyledThemeProvider theme={theme === 'light' ? libraryThemeLight : libraryThemeDark}>
          <Routes />
        </StyledThemeProvider>
      </ThemeProvider>
    </ReactKeycloakProvider>
  )
}

export default App
