
const dark = {
  platform: {
    fontColor: '#F2F3F7',
    pages: {
      home: {
        mainColor: '#151D28'
      },
      firstAccessModal: {
        background: '#222E3E',
        border: '#364353',
        title: '#F2F3F7',
        subtitle: '#B5B8C2',
        inputLabel: '#F2F3F7',
        inputBorder: '#364353',
        footerBackground: '#505F73'
      }
    }
  }
}

const light = {
  platform: {
    fontColor: '#222222',
    pages: {
      home: {
        mainColor: '#F2F3F7'
      },
      firstAccessModal: {
        background: '#FFFFFF',
        border: '#D9DCE6',
        title: '#000000',
        subtitle: '#222222',
        inputLabel: '#545454',
        inputBorder: '#0000006b',
        footerBackground: '#F0F1F5'
      }
    }
  }
}

export { light, dark }
