import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { light } from '../../../application/themes'
import wave from '../../assets/ondas-1.png'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${(props: any) => props.theme?.platform?.pages?.home?.mainColor};
  background-image: url(${wave});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  overflow-y: auto;
  font-family: DMSans-Regular;
`

const Container = styled(Grid)`
  margin-top: 60px;
  margin-bottom: 20px;

  @media (max-width: 1366px) {
    margin-top: 20px;
  }
`

const Item = styled(Grid)`
  text-align: center;
`

const ItemContainer = styled.div`
  display: flex; 
  flex-direction: column;
  text-align: left;
  padding-left: 18px;
`

const BottomItem = styled(Grid)`
  justify-content: center;
  margin-bottom: 60px;

  @media (max-width: 1366px) {
    margin-bottom: 25px;
  }
`

const Title = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 2%;

  @media (max-width: 1366px) {
    font-size: 30px;
  }
`

const SubTitle = styled.h2`
  font-weight: 400;
  font-size: 18px;
  color: #545454;
  line-height: 27px;
  letter-spacing: 2%;

  @media (max-width: 1366px) {
    font-size: 16px;
  }
`

const TextPrimary = styled.span`
  font-size: 18px;
  color: ${light.platform.fontColor};

  @media (max-width: 1366px) {
    font-size: 16px;
  }
`

const TextSecondary = styled.a`
  font-size: 14px;
  color: #545454;

  @media (max-width: 1366px) {
    font-size: 12px;
  }
`

export {
  MainContainer,
  Container,
  ItemContainer,
  Title,
  SubTitle,
  Item,
  BottomItem,
  TextPrimary,
  TextSecondary
}
