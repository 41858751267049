import styled from 'styled-components'

const ContainerModule = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: DMSans-Regular;
  padding-left: 111px;
  box-sizing: border-box;
  padding-top: 5%;
  & > img {
    width: 10%;
  }
`

const Title = styled.h1`
  font-weight: 700;
  font-size: 32px;
  margin-top: 67px;
  line-height: 150%;
  letter-spacing: 0.02em;
`

const SubTitle = styled.h2`
  font-weight: 400;
  font-size: 18px;
  color: #545454;
  line-height: 150%;
  letter-spacing: 0.02em;
`

export { Title, SubTitle, ContainerModule }
