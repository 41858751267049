import React from 'react'

import { I18n } from 'react-redux-i18n'

import warningModule from '../../../assets/warning-module.svg'
import { ContainerModule, SubTitle, Title } from './style'

const NotFound = (): JSX.Element => {
  return (
    <ContainerModule>
      <img src={warningModule} alt={I18n.t('app.pages.home.alert')} />
      <Title>{I18n.t('pages.home.whatPity')}</Title>
      <SubTitle>{I18n.t('pages.home.notPermissionModule')}</SubTitle>
    </ContainerModule>
  )
}

export default NotFound
