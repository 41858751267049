import React, { useCallback } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'

const Login = (): JSX.Element => {
  const location = useLocation<{ [key: string]: unknown }>()
  const currentLocationState = location.state !== ''
    ? location.state
    : { from: { pathname: '/' } }

  const { keycloak } = useKeycloak()

  const login = useCallback(() => {
    keycloak?.login()
  }, [keycloak])

  const goToLogin: any = async () => {
    return await new Promise<void>((resolve) => {
      setTimeout(() => resolve(), 300)
    }).then(() => {
      if ((keycloak?.authenticated) === false) {
        void login()
      }
    })
  }

  if ((keycloak?.authenticated) === true) {
    return <Redirect to={currentLocationState?.from as string} />
  }

  goToLogin()

  return (
    <div />
  )
}

export default Login
