import React from 'react'

import CButton from './style'
import { withTheme } from 'styled-components'

interface CustomButtonProps {
  outlined: boolean
  onPress: () => void
  id: string
  children: any
}

const CustomButton = ({ outlined, onPress, id, children }: CustomButtonProps): JSX.Element => {
  return (
      <CButton id={id} variant="contained" onClick={onPress} outlined={outlined}>
        {children}
      </CButton>
  )
}

export default withTheme(CustomButton)
