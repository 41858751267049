import React from 'react'

import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router-dom'

import warningModule from '../../assets/warning-module.svg'
import phoneIcon from '../../assets/phone-icon.svg'
import CustomButton from '../../components/CustomButton'
import {
  MainContainer,
  Container,
  ItemContainer,
  Title,
  SubTitle,
  Item,
  BottomItem,
  TextPrimary,
  TextSecondary
} from './style'

const SignUpFail = (): JSX.Element => {
  const history = useHistory()

  return (
    <MainContainer id="mainContainer">
      <Container container direction="column" xs={12} md={5} lg={4} alignItems="center">
        <img src={warningModule} alt={I18n.t('app.pages.home.alert')} />
        <Item>
          <Title>{I18n.t('pages.signUpFail.title')}</Title>
        </Item>
        <Item item>
          <SubTitle>{I18n.t('pages.signUpFail.subtitle1')}</SubTitle>
        </Item>
        <Item item>
          <SubTitle>{I18n.t('pages.signUpFail.subtitle2')}</SubTitle>
        </Item>
        <BottomItem container alignItems="center">
          <img src={phoneIcon} alt={I18n.t('app.pages.home.alert')} />
          <ItemContainer>
            <TextPrimary>(11) 3218-7070</TextPrimary>
            <TextSecondary href="https://fiorde.atlassian.net/servicedesk/customer/portal/7">Help Center</TextSecondary>
          </ItemContainer>
        </BottomItem>
        <CustomButton onPress={() => history.push('/login')}>{I18n.t('pages.signUpFail.button')}</CustomButton>
      </Container>
    </MainContainer>
  )
}

export default SignUpFail
