// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./application/fonts/DMSans-Regular.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"DMSans-Regular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\nhtml,\nbody {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n}\n\n#single-spa-application\\:\\@sirius\\/platform-core-fe {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n}\n\n@media (max-height: 668px)\n{\n  #single-spa-application\\:\\@sirius\\/platform-core-fe {\n    height: unset;\n  }\n    \n}\n\n\n* {\n  font-family: \"DMSans-Regular\" !important;\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,+DAAqE;EACrE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;;EAEE,SAAS;EACT,UAAU;EACV,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,MAAM;AACR;;AAEA;;EAEE;IACE,aAAa;EACf;;AAEF;;;AAGA;EACE,wCAAwC;AAC1C","sourcesContent":["@font-face {\n  font-family: \"DMSans-Regular\";\n  src: url(\"./application/fonts/DMSans-Regular.ttf\") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\nhtml,\nbody {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n}\n\n#single-spa-application\\:\\@sirius\\/platform-core-fe {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n}\n\n@media (max-height: 668px)\n{\n  #single-spa-application\\:\\@sirius\\/platform-core-fe {\n    height: unset;\n  }\n    \n}\n\n\n* {\n  font-family: \"DMSans-Regular\" !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
