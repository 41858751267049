import React, { useState } from 'react'
import { LogoIcon, Alert } from 'fiorde-fe-components'
import PasswordContainer from '../../components/PasswordContainer'
import { MainContainer, Container, Error, InputContainer, ButtonContainer } from './style'
import { I18n } from 'react-redux-i18n'
import ArrowButton from '../../components/ArrowButton'
import CustomButton from '../../components/CustomButton'

const Register = (): JSX.Element => {
  const [passwords, setPasswords] = useState({ new: '', repeat: '' })
  const [showPasswords, setShowPasswords] = useState({ new: false, repeat: false })
  const [showError, setShowError] = useState({ new: false, repeat: false })
  const [alert, setAlert] = useState({ show: false, message: '' })

  const checkPasswords = (newp, repeatp): string => {
    if (newp.length < 8 || repeatp.length < 8) {
      return (I18n.t('pages.register.errors.eightChars'))
    }
    if (!(/^(?=.*\d)/).test(newp) || !(/^(?=.*\d)/).test(repeatp)) {
      return (I18n.t('pages.register.errors.oneDigit'))
    }
    if (!(/^(?=.*[!@#$%^&*])/).test(newp) || !(/^(?=.*[!@#$%^&*])/).test(repeatp)) {
      return (I18n.t('pages.register.errors.specialChar'))
    }
    if (!(/^(?=.*[a-z])(?=.*[A-Z])/).test(newp) || !(/^(?=.*[a-z])(?=.*[A-Z])/).test(repeatp)) {
      return (I18n.t('pages.register.errors.upperLower'))
    }

    return ''
  }

  const validPasswords = (): boolean => {
    const newp = passwords.new
    const repeatp = passwords.repeat

    if (newp !== '' && repeatp !== '') {
      if (newp === repeatp) {
        const msg = checkPasswords(newp, repeatp)
        if (msg !== '') {
          setAlert({
            show: true,
            message: msg
          })
          return false
        }
      } else {
        setAlert({ show: true, message: I18n.t('pages.register.errors.differentPwds') })
        return false
      }
    } else {
      setShowError({
        ...showError,
        new: newp === '',
        repeat: repeatp === ''
      })

      return false
    }

    return true
  }

  const handleRegister = (): void => {
    setAlert({ show: false, message: '' })
    if (validPasswords()) {
      // chamar endpoint para cadastrar dados no banco
      console.log('Cadastrar dados no banco')
    }
  }

  const handleClick = (name: any, value: boolean): void => {
    setShowPasswords({ ...showPasswords, [name]: !value })
  }

  const handleChange = (evt): void => {
    const value = evt.target.value
    setPasswords({
      ...passwords,
      [evt.target.name]: value
    })
    setShowError({
      ...showError,
      [evt.target.name]: false
    })
  }

  return (
    <MainContainer id="mainContainer">
      <LogoIcon id="logoIcon" />
      <Container id="container">
        <p id="labelMessage">{I18n.t('pages.register.registerPwd')}</p>
        <Error>
          {alert.show && (
            <Alert backgroundColor="#B85151" fontColor="#FFFFFF">
              <span>{alert.message}</span>
            </Alert>
          )}
        </Error>
        <InputContainer>
          <PasswordContainer
            id="password"
            name="new"
            label={I18n.t('pages.register.newPassword')}
            value={passwords.new}
            showError={showError.new}
            errMsg={I18n.t('pages.register.errors.requiredPwd')}
            showPasswords={showPasswords.new}
            handleChange={handleChange}
            handleClick={() => handleClick('new', showPasswords.new)}
          />
          <PasswordContainer
            id="repeat-password"
            name="repeat"
            label={I18n.t('pages.register.repeatPassword')}
            value={passwords.repeat}
            showError={showError.repeat}
            errMsg={I18n.t('pages.register.errors.requiredRepeatPwd')}
            showPasswords={showPasswords.repeat}
            handleChange={handleChange}
            handleClick={() => handleClick('repeat', showPasswords.repeat)}
          />
        </InputContainer>
        <ButtonContainer>
          <ArrowButton id="cancelButton" onPress={() => window.open('/', '_self')} position='left'>
            {I18n.t('pages.register.cancel')}
          </ArrowButton>
          <CustomButton id="registerButton" onPress={handleRegister}>
            {I18n.t('pages.register.registerAccount')}
          </CustomButton>
        </ButtonContainer>
      </Container>
    </MainContainer>
  )
}

export default Register
