import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { light } from '../../../application/themes'

interface CButtonProps {
  outlined: boolean
  theme: any
}

const CButton = styled(Button)`
  && {
    background-color: ${({ outlined }: CButtonProps) => (outlined ? 'transparent' : '#43BFB5')};
    border: ${({ outlined }: CButtonProps) => (outlined ? '1px solid #43BFB5' : 'none')};
    border-radius: 4px;
    height: 40px;
    align-self: center;
    color: ${(props: CButtonProps) =>
      props.outlined ? props.theme?.platform?.fontColor : light.platform?.fontColor};
    text-transform: none;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 16px;
    font-weight: 500;
    font-family: DMSans-Regular;
    &:hover {
      background-color: ${({ outlined }: CButtonProps) => (outlined ? '#2E99900D' : '#2E9990')};
    };
  }
`

export default CButton
