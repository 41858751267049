import { TOGGLE_THEME } from '../actionTypes/app'
import { emitEvent } from '../../utils/event'

const initialState = {
  theme: localStorage.getItem(TOGGLE_THEME) ?? 'light'
}
const app = (state = initialState, action): any => {
  switch (action.type) {
    case TOGGLE_THEME: {
      const theme = action.payload
      localStorage.setItem(TOGGLE_THEME, theme)
      // emiting to another micro frontends
      emitEvent('theme', { theme })
      return { ...state, theme }
    }
    default:
      return state
  }
}

export default app
