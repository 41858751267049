import React from 'react'
import { TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { VisibilityIcon, VisibilityOffIcon } from 'fiorde-fe-components'

const PasswordContainer = (props): JSX.Element => {
  const { id, name, label, value, showError, errMsg, showPasswords, handleChange, handleClick } = props
  return (
    <TextField
      id={id}
      name={name}
      label={label}
      value={value}
      error={showError}
      helperText={showError as boolean && errMsg}
      type={showPasswords as boolean ? 'text' : 'password'}
      margin="normal"
      onChange={handleChange}
      size="small"
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClick}
            >
              {showPasswords as boolean ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
      }}
    />
  )
}

export default PasswordContainer
