import styled from 'styled-components'
interface WrapperProps {
  isHome: boolean
}

const RootContainer = styled.div`
    height: 100%;
    width: 100%;
`

const SideMenuContainer = styled.div`
    position: fixed;
    left: 0;
    height: 100%;   
`

const MainContainer = styled.div`
    padding-left: ${(props: WrapperProps) => props.isHome ? '0' : '88px'};
    padding-top: 64px;
    height: 100%;
    box-sizing: border-box;
`

const HeaderContainer = styled.div`
    position: fixed;
    left:  ${(props: WrapperProps) => props.isHome ? '0' : '88px'};
    z-index: 1299;
    width: ${(props: WrapperProps) => props.isHome ? '100%' : 'calc(100% - 88px)'};
`

export { RootContainer, SideMenuContainer, MainContainer, HeaderContainer }
