import { useKeycloak } from '@react-keycloak/web'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ children, ...rest }): JSX.Element => {
  const { keycloak } = useKeycloak()

  return (
    <Route
      {...rest}
    >
      {(keycloak?.authenticated === true)
        ? children
        : <Redirect to={{ pathname: '/login' }} />
      }
    </Route>
  )
}

export default PrivateRoute
