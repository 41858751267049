import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LogoIcon } from 'fiorde-fe-components'
import { TextField } from '@material-ui/core'
import { I18n } from 'react-redux-i18n'
import { MainContainer, Container, InputContainer, ButtonContainer } from './style'
import ArrowButton from '../../components/ArrowButton'
import CustomButton from '../../components/CustomButton'

const SignUp = (): JSX.Element => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [showError, setShowError] = useState({ show: false, msg: '' })

  const validateEmail = (): boolean => {
    const regex = /\S+@\S+\.\S+/
    return regex.test(email)
  }

  const handleChange = (value): void => {
    setEmail(value)
    setShowError({ show: false, msg: '' })
  }

  const handleClick = (): void => {
    window.open('/', '_self')
  }

  const handleVerify = (): void => {
    if (email !== '') {
      if (validateEmail()) {
        // Chamar endpoint do keycloak aqui, para verificar se usuario existe
        history.push('/register')
      } else {
        setShowError({ show: true, msg: I18n.t('pages.signup.errors.invalidEmail') })
      }
    } else {
      setShowError({ show: true, msg: I18n.t('pages.signup.errors.requiredEmail') })
    }
  }

  return (
    <MainContainer id="mainContainer">
      <LogoIcon id="logoIcon" />
      <Container id="container">
        <p id="labelMessage">{I18n.t('pages.signup.enterEmail')}</p>
        <InputContainer>
          <TextField
            error={showError.show}
            helperText={showError.show && showError.msg}
            id="user-email"
            name="user"
            value={email}
            label="E-mail"
            margin="normal"
            onChange={(e) => handleChange(e.target.value)}
            size="small"
          />
        </InputContainer>
        <ButtonContainer>
          <ArrowButton id="cancelButton" onPress={handleClick} position='left'>
            {I18n.t('pages.signup.cancel')}
          </ArrowButton>
          <CustomButton id="verifyButton" onPress={handleVerify}>
            {I18n.t('pages.signup.verify')}
          </CustomButton>
        </ButtonContainer>
      </Container>
    </MainContainer>
  )
}

export default SignUp
