import styled from 'styled-components'
import { Button } from '@material-ui/core'

const CButton = styled(Button)`
  && {
    height: 40px;
    align-self: center;
    text-transform: none;
    font-size: 14px;
    font-family: DMSans-Regular;
  }
`

export default CButton
