import styled from 'styled-components'
import wave from '../../assets/ondas-1.png'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${(props: any) => props.theme?.platform?.pages?.home?.mainColor};
  background-image: url(${wave});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  overflow-y: auto;
  font-family: DMSans-Regular;
  & > div > svg {
    width: 212px;
    height: 64px;
    margin: 95px 0 62px 0;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  & > p {
    width: 300px;
    text-align: center;
    margin: 0;
    font-size: 14px;
    color: ${(props: any) => props.theme?.platform?.fontColor};
  }
`

const InputContainer = styled.div`
  & .MuiFormControl-root {
    width: 370px;
    margin-top: 62px;
    p {
      color: #CC3D3D;
      font-family: DMSans-Regular;
      align-self: flex-end;
      width: 165px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & .MuiInputLabel-formControl {
    left: 7px;
    color: #545454;
    font-size: 14px;
    font-family: DMSans-Regular;
  }

  & .MuiInputBase-input {
    color: #545454;
    padding-top: 6px;
    font-family: DMSans-Regular;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 60px;
  width: 370px;
  justify-content: space-between;
`

export { MainContainer, Container, InputContainer, ButtonContainer }
